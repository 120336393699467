// This will be moved to the styles.scss folder when completed

@mixin flex-layout-align-start-stretch($direction) {
    .flex-layout-align-start-stretch {
        flex-direction: $direction;

        @if $direction ==column {
            max-width: 100%;
        }

        @else if $direction ==row {
            max-height: 100%;
        }
    }
}

@include flex-layout-align-start-stretch(column);
@include flex-layout-align-start-stretch(row);


// To be applied on parent elements
.flex {
    &-layout {
        &-row {
            display: flex;
            flex-direction: row;
        }  
        
        &-row-reverse {
            display: flex;
            flex-direction: row-reverse;
        }

        &-col {
            display: flex;
            flex-direction: column;
        }

        &-col-reverse {
            display: flex;
            flex-direction: column-reverse;
        }

        &-align {
            &-start {
                &-start {
                    justify-content: flex-start;
                    align-items: flex-start;
                }
        
                &-center {
                    justify-content: flex-start;
                    align-items: center;
                }
        
                &-end {
                    justify-content: flex-start;
                    align-items: flex-end;
                }
                // max-width: 100% if flex-direction: column; else max-height: 100%. To be added to final mixin
                &-stretch {
                    justify-content: flex-start;
                    align-items: stretch;
                }
        
                &-baseline {
                    justify-content: flex-start;
                    align-items: baseline;
                }
            }
        
            &-center {
                &-start {
                    justify-content: center;
                    align-items: flex-start;
                }
        
                &-center {
                    justify-content: center;
                    align-items: center;
                }
        
                &-end {
                    justify-content: center;
                    align-items: flex-end;
                }
                // max-width: 100% if flex-direction: column; else max-height: 100%. To be added to final mixin
                &-stretch {
                    justify-content: center;
                    align-items: stretch;
                }
        
                &-baseline {
                    justify-content: center;
                    align-items: baseline;
                }
            }
        
            &-end {
                &-start {
                    justify-content: flex-end;
                    align-items: flex-start;
                }
        
                &-center {
                    justify-content: flex-end;
                    align-items: center;
                }
        
                &-end {
                    justify-content: flex-end;
                    align-items: flex-end;
                }
                // max-width: 100% if flex-direction: column; else max-height: 100%. To be added to final mixin
                &-stretch {
                    justify-content: flex-end;
                    align-items: stretch;
                }
        
                &-baseline {
                    justify-content: flex-end;
                    align-items: baseline;
                }
            }
        
            &-around {
                &-start {
                    justify-content: space-around;
                    align-items: flex-start;
                }
        
                &-center {
                    justify-content: space-around;
                    align-items: center;
                }
        
                &-end {
                    justify-content: space-around;
                    align-items: flex-end;
                }
                // max-width: 100% if flex-direction: column; else max-height: 100%. To be added to final mixin
                &-stretch {
                    justify-content: space-around;
                    align-items: stretch;
                }
        
                &-baseline {
                    justify-content: space-around;
                    align-items: baseline;
                }
            }
        
            &-between {
                &-start {
                    justify-content: space-between;
                    align-items: flex-start;
                }
        
                &-center {
                    justify-content: space-between;
                    align-items: center;
                }
        
                &-end {
                    justify-content: space-between;
                    align-items: flex-end;
                }
                // max-width: 100% if flex-direction: column; else max-height: 100%. To be added to final mixin
                &-stretch {
                    justify-content: space-between;
                    align-items: stretch;
                }
        
                &-baseline {
                    justify-content: space-between;
                    align-items: baseline;
                }
            }
            &-evenly {
                &-start {
                    justify-content: space-evenly;
                    align-items: flex-start;
                }
        
                &-center {
                    justify-content: space-evenly;
                    align-items: center;
                }
        
                &-end {
                    justify-content: space-evenly;
                    align-items: flex-end;
                }
                // max-width: 100% if flex-direction: column; else max-height: 100%. To be added to final mixin
                &-stretch {
                    justify-content: space-evenly;
                    align-items: stretch;
                }
        
                &-baseline {
                    justify-content: space-evenly;
                    align-items: baseline;
                }
            }
            &-wrap {
                flex-wrap: wrap;

                &-content-start {
                    align-content: flex-start;
                }

                &-content-end {
                    align-content: flex-end;
                }

                &-content-center {
                    align-content: center;
                }

                &-content-space-between {
                    align-content: space-between;
                }

                &-content-space-around {
                    align-content: space-around;
                }

                &-content-stretch {
                    align-content: stretch;
                }
            }

            &-wrap-reverse {
                flex-wrap: wrap-reverse;
            }

            &-nowrap {
                flex-wrap: nowrap;
            }
        }
    }
}


//  Applied to children elements of parent with display flex only. If display block (which is the default) properties won't be applied
.flex {
    &-0 {
        &-0 {
            &-auto {
                flex: 0 0 auto;
            }

            &-0 {
                flex: 0 0 0%;
            }

            &-20 {
                flex: 0 0 20%;
            }

            &-25 {
                flex: 0 0 25%;
            }

            &-30 {
                flex: 0 0 33%;
            }

            &-50 {
                flex: 0 0 50%;
            }

            &-60 {
                flex: 0 0 50%;
            }

            &-75 {
                flex: 0 0 75%;
            }

            &-100 {
                flex: 0 0 100%;
            }

        }

        &-1 {
            &-auto {
                flex: 0 1 auto;
            }

            &-0 {
                flex: 0 1 0%;
            }

            &-20 {
                flex: 0 1 20%;
            }

            &-25 {
                flex: 0 1 25%;
            }

            &-30 {
                flex: 0 1 30%;
            }

            &-50 {
                flex: 0 1 50%;
            }

            &-60 {
                flex: 0 1 60%;
            }

            &-75 {
                flex: 0 1 75%;
            }

            &-100 {
                flex: 0 1 100%;
            }

        }
    }

    &-1 {
        &-0 {
            &-auto {
                flex: 1 0 auto;
            }

            &-0 {
                flex: 1 0 0%;
            }

            &-20 {
                flex: 1 0 20%;
            }

            &-25 {
                flex: 1 0 25%;
            }

            &-30 {
                flex: 1 0 30%;
            }

            &-50 {
                flex: 1 0 50%;
            }

            &-60 {
                flex: 1 0 60%;
            }

            &-75 {
                flex: 1 0 75%;
            }

            &-100 {
                flex: 1 0 100%;
            }

        }

        &-1 {
            &-auto {
                flex: 1 1 auto;
            }

            &-0 {
                flex: 1 1 0%;
            }

            &-20 {
                flex: 1 1 20%;
            }

            &-25 {
                flex: 1 1 25%;
            }

            &-30 {
                flex: 1 1 30%;
            }

            &-50 {
                flex: 1 1 50%;
            }

            &-60 {
                flex: 1 1 60%;
            }

            &-75 {
                flex: 1 1 75%;
            }

            &-100 {
                flex: 1 1 100%;
            }

        }
    }
}


.grid {
    display: grid;
}

.flex-wrap {
    flex-wrap: wrap;
}

// Main Axis and cross axis
// max-width: 100% if flex-direction: column; else max-height: 100%. To be added to final mixin
.padding {
    &-0 {
        &-top {
            padding-top: 0;
        }
    
        &-bottom {
            padding-bottom: 0;
        }
    
        &-left {
            padding-left: 0;
        }
    
        &-right {
            padding-right: 0;
        }
    
        &-all {
            padding: 0;
        }
    }
    &-1 {
        &-top {
            padding-top: 8px;
        }
    
        &-bottom {
            padding-bottom: 8px;
        }
    
        &-left {
            padding-left: 8px;
        }
    
        &-right {
            padding-right: 8px;
        }
    
        &-all {
            padding: 8px;
        }
    }
    &-2 {
        &-top {
            padding-top: 16px;
        }
    
        &-bottom {
            padding-bottom: 16px;
        }
    
        &-left {
            padding-left: 16px;
        }
    
        &-right {
            padding-right: 16px;
        }
    
        &-all {
            padding: 16px;
        }
    }
    &-3 {
        &-top {
            padding-top: 24px;
        }
    
        &-bottom {
            padding-bottom: 24px;
        }
    
        &-left {
            padding-left: 24px;
        }
    
        &-right {
            padding-right: 24px;
        }
    
        &-all {
            padding: 24px;
        }
    }
    &-4 {
        &-top {
            padding-top: 32px;
        }
    
        &-bottom {
            padding-bottom: 32px;
        }
    
        &-left {
            padding-left: 32px;
        }
    
        &-right {
            padding-right: 32px;
        }
    
        &-all {
            padding: 32px;
        }
    }
    &-5 {
        &-top {
            padding-top: 32px;
        }
    
        &-bottom {
            padding-bottom: 32px;
        }
    
        &-left {
            padding-left: 32px;
        }
    
        &-right {
            padding-right: 32px;
        }
    
        &-all {
            padding: 32px;
        }
    }
}

